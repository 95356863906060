import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Dashboard",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views-giveproudly/give-proudly-Dashboard.vue"),
  },
  {
    path: "/CharityManagement",
    name: "Campaign Management",
    //name: "Charity Management",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views-giveproudly/CharityManagement.vue"),
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Users.vue"),
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Coupons.vue"),
  },
  {
    path: "/CharityManagement/Charityprofile/:userId",
    name: "Campaign Profile",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views-giveproudly/CharityProfile.vue"),
      meta: {
        subPage: "Profile",
        parentPage: "Campaign Management"
      },
  },
  {
    path: "/users/:userId",
    name: "UsersProfile",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/UsersProfile.vue"),
    meta: {
      subPage: "Profile",
      parentPage: "Users"
    },
  },
  {
    path: "/videos",
    name: "Videos",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Videos.vue"),
  },
  {
    path: "/videos/add",
    name: "VideosCreate",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/VideosCreate.vue"),
    meta: {
      subPage: "Add",
      parentPage: "Target Video"
    },
  },
  {
    path: "/videos/edit/:id",
    name: "VideosEdit",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/VideosCreate.vue"),
    meta: {
      subPage: "Edit",
      parentPage: "Videos",
      isEdit: true
    },
  },
  {
    path: "/videos/add-slide",
    name: "ComplianceSlideAdd",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/VideosSlideCreate.vue"),
    meta: {
      subPage: "Add",
      parentPage: "Compliance Slide"
    },
  },
  {
    path: "/videos/edit-slide/:id",
    name: "ComplianceSlideEdit",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/VideosSlideCreate.vue"),
    meta: {
      subPage: "Edit",
      parentPage: "Compliance Slide",
      isEdit: true
    },
  },
  {
    path: "/passwords",
    name: "Passwords",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Passwords.vue"),
  },
 
  {
    path: "/App-settings",
    name: "App Settings",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views-giveproudly/App-settings.vue"),
  },
  
  {
    path: "/content",
    name: "Content",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Content.vue"),
  },
  {
    path: "/settings",
    name: "Account Settings",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Settings.vue"),
  },
  {
    path: "/Audios",
    name: "Audios",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views-giveproudly/Audios.vue"),
  },
  {
    path: "/administrators",
    name: "Administrators",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Admins.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views-giveproudly/give-proudly-Login.vue"),
  },
  {
    path: "/register",
    name: "MasterRegistration",
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/MasterRegistration.vue"),
  },
  {
    path: "/forgot",
    name: "ForgotPasswordEmail",
    component: () =>
      import(
        /* webpackChunkName: "forgot" */
        "@/views-giveproudly/ForgotPassword/give-proudly-ForgotPasswordEmail.vue"
      ),
  },
  {
    path: "/forgot/code",
    name: "ForgotPasswordCode",
    component: () =>
      import(
        /* webpackChunkName: "forgot" */
        "@/views-giveproudly/ForgotPassword/give-proudly-ForgotPasswordCode.vue"
      ),
  },
  {
    path: "/forgot/change",
    name: "ForgotPasswordChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "forgot" */
        "@/views-giveproudly/ForgotPassword/give-proudly-ForgotPasswordChangePassword.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let adminDetails = null;
  let isMasterAdmin = null;
  if (localStorage.getItem("adminDetails")) {
    adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
  }
  if (adminDetails && adminDetails.adminType == "MASTER") {
    isMasterAdmin = true;
  }
  // if not logged in, redirect to login page
  if (to.name === "Dashboard" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "Users" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "UsersProfile" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "Videos" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "VideosCreate" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "VideosEdit" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "ComplianceSlideAdd" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "ComplianceSlideEdit" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "Passwords" && !adminDetails) next({
    name: "Login"
  });
  if (to.name === "Account Settings" && !adminDetails) next({
    name: "Login"
  });
  // only accessibleby master admin
  if (to.name === "Admins" && !adminDetails && isMasterAdmin) next({
    name: "Login"
  });
  else next();
});
export default router;