// import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";

// external plugins
import "./plugins/bootstrap-vue";
import "./plugins/fontawesome-vue";
import "./plugins/apexcharts-vue";
import "./plugins/editor-vue";
import "./plugins/recaptcha-vue";
import "./plugins/composition-api";

import App from "./App.vue";
import personalPacRouter from "./router/personal-pac";
import giveProudlyRouter from "./router/give-proudly";

const router =
  process.env.VUE_APP_NAME === "give-proudly"
    ? giveProudlyRouter
    : personalPacRouter ;

import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.version = window.version;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
